<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ apiDataItem.id ? '編輯信箱API' : '新增信箱API' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 信箱API名稱 -->
        <validation-provider
          #default="{ errors }"
          name="信箱API名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                信箱API名稱
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="apiData.name"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="apiDataItem.name ? apiDataItem.name : '請輸入信箱API名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-group label-for="content">
          <template #label>
            <label class="mb-0">
              說明
            </label>
          </template>
          <b-form-input
            id="content"
            v-model="apiData.content"
            trim
            :placeholder="apiDataItem.content ? apiDataItem.content : '請輸入說明'"
          />
        </b-form-group>
      </b-form>

      <div>
        <!-- 基本參數 -->
        <div class="mb-1">
          <div class="d-flex align-items-center justify-content-between font-weight-bold">
            <div>
              <feather-icon
                icon="BoxIcon"
                class="mr-50"
                style="margin-top: -3px"
              />

              <span style="font-size: '16px'">參數設定</span>
            </div>

            <div>
              <div
                v-if="apiData.config.length > 0"
                class="actions-link-btn mr-25"
                @click="cleanAllRules"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="一鍵清空"
                  :src="require('@/assets/images/pages/ui/blue-line/trash-bin.svg')"
                  class="actions-link-btn-image"
                  width="14"
                  rounded
                />
              </div>
            </div>
          </div>

          <hr class="mt-50">

          <div
            v-for="(element, index) in apiData.config"
            :key="`config-${element.key}`"
          >
            <b-form-group
              :label-for="element.key"
              :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
            >
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">
                    {{ element.label }}
                    <small>( {{ element.key }} )</small>
                  </label>
                </div>
              </template>

              <div class="d-flex text-nowrap align-items-center">
                <b-form-input
                  :id="element.key"
                  v-model="element.value"
                  trim
                  :placeholder="`請輸入${element.label}`"
                />

                <div class="d-flex text-nowrap align-items-center">
                  <b-dropdown
                    variant="link"
                    no-caret
                    right
                    toggle-class="p-50"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="selectedEditRow(element, index)"
                    >
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>編輯</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="removeItem(element, index)"
                    >
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>移除</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>

        <!-- 新增參數 -->
        <div class="text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="addNewItem"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>新增參數</span>
          </b-button>
        </div>

        <api-rule
          ref="paymentRuleEditModal"
          :config-array="apiData.config"
          :selected-index="selectedIndex"
          payment-rule-id="payment-rule-edit-modal"
          @edit-rule="editPaymentData"
        />
      </div>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, VBTooltip, BDropdownItem,
  BDropdown, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import formValidation from '@core/comp-functions/forms/form-validation'
import ApiRule from './components/ApiRule.vue'
import { useApiList, useApiSetting } from '../useApi'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BForm,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BDropdownItem,
    BDropdown,

    ValidationProvider,
    ValidationObserver,
    ApiRule,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    apiDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      selectedIndex: null,
      missingFields: [],
    }
  },
  methods: {

    // (編輯)參數設定
    editPaymentData(item) {
      const resolveData = this.syncObject(this.blankSetting, item)
      if (this.selectedIndex !== null) {
        this.$set(this.apiData.config, this.selectedIndex, resolveData)
        return
      }
      this.apiData.config.push(resolveData)
    },

    // (觸發)編輯規則
    selectedEditRow(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.paymentRuleEditModal.getData(item) }, 200)
    },

    // (觸發)新增規則
    addNewItem() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankSetting))
      this.selectedIndex = null
      this.$refs.paymentRuleEditModal.getData(resolveData)
    },

    // (移除)所有規則
    cleanAllRules() {
      if (this.isBusy) return
      this.$swal({
        title: '刪除所有規則',
        text: '你確定要一鍵清除所有規則設定嗎?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.apiData.config = []
        }
      })
    },

    // (觸發)移除規則
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除參數',
        text: `你確定要刪除參數 ${item.label} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.apiData.config.splice(index, 1)
        }
      })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetAPIData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '信箱API名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.apiData[Object.keys(requiredFields)[i]] === null || this.apiData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true

      if (this.apiDataItem.id) {
        this.setAPIMailUpdate({
          mail_api_id: this.apiDataItem.id,
          data: {
            ...this.apiData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetAPIData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setAPIMailCreate({
          ...this.apiData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetAPIData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankAPIData, this.apiDataItem),
      }
      this.apiData = resolveData
      this.$bvModal.show('update-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      setAPIMailCreate,
      setAPIMailUpdate,
    } = useApiList()

    const {
      stateOptions,
      getMetaListData,
      departmentsOptions,
      syncObject,
      blankSetting,
    } = useApiSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankAPIData = {
      name: null,
      content: null,
      config: [{
        key: 'username',
        label: '使用者名稱',
        value: null,
      }, {
        key: 'password ',
        label: '密碼',
        value: null,
      }, {
        key: 'port',
        label: 'PORT',
        value: null,
      }, {
        key: 'host',
        label: 'Host',
        value: null,
      }],
    }

    const apiData = ref(JSON.parse(JSON.stringify(blankAPIData)))

    const resetAPIData = () => {
      apiData.value = JSON.parse(JSON.stringify(blankAPIData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAPIData)

    return {
      isBusy,
      blankSetting,

      apiData,
      stateOptions,
      departmentsOptions,
      blankAPIData,
      setAPIMailCreate,
      setAPIMailUpdate,
      getMetaListData,

      resetAPIData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}

@keyframes btn-breathe-todo-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}
</style>
